import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

function _defaultStates() {
  return {
    userInfo: { name: '', isAdmin: false, roles: [] },
    currentPageTitle: '',
    currentModule: '',
    selectedBotId: null,
    selectedBotName: null,
    selectedSkypeBot: null,
    skypeBots: [],
    appConfigs: [],
  };
}

const states = _defaultStates();

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: states,
  mutations: {
    resetStates(state) {
      Object.assign(state, _defaultStates());
    },
    updateUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    updatePageTitle(state,title) {
      state.currentPageTitle = title;
    },
    updateCurrentModule(state, module) {
      state.currentModule = module;
    },
    updateSelectedBotId(state, botId) {
      state.selectedBotId = botId;
    },
    updateSelectedBotName(state, botName) {
      state.selectedBotName = botName;
    },
    updateSkypeBots(state, skypeBots) {
      state.skypeBots = skypeBots;
    },
    updateAppConfigs(state, appConfigs) {
      state.appConfigs = appConfigs;
    },
    updateSelectedSkypeBot(state, skypeBot) {
      state.selectedSkypeBot = skypeBot;
    },
  },
  actions: {},
  modules: {},
});
