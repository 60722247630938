import axios from 'axios';
import store from '@/store/index';
const instance = axios.create({
  baseURL: window.location.origin
});
export default {
  load: () => {
    instance.get('api/security/appConfigs').then((resp) => {
      if (Array.isArray(resp.data)) {
        store.commit('updateAppConfigs', resp.data);
      }
    });
  },
  LIST_PLATFORMS: 'ListPlatforms',
  INTENT_ACTIVITY_LOG_START_DATETIME: 'IntentActivityLogStartDatetime',
};
