export const messages = {
  en: {
    client: {
      '0001': 'Please enter a question',
      '0002': 'Confirm',
      '0003': 'Are you sure you want to terminate the chat?',
      '0004': 'Remind',
      '0005': 'Please enter required (*) form',
      '0006': 'Close',
      '0007': 'No chat history',
      '0008': 'Form entry',
      '0009': 'Recognition Language',
      '0010': 'Settings',
      '0011': 'Start chatting again',
    },
  },
  zh: {
    client: {
      '0001': '请输入问题',
      '0002': '确定',
      '0003': '您确定要终止聊天吗？',
      '0004': '提醒',
      '0005': '请输入必填（*）表格 ',
      '0006': '关闭',
      '0007': '没有聊天记录',
      '0008': '表格输入',
      '0009': '识别语言',
      '0010': '设定',
      '0011': '再次开始聊天',
    },
  },
  vn: {
    client: {
      '0001': 'Vui lòng nhập một câu hỏi',
      '0002': 'Được rồi',
      '0003': 'Bạn có chắc chắn muốn chấm dứt trò chuyện?',
      '0004': 'Nhắc nhở',
      '0005': 'Vui lòng nhập mẫu (*) bắt buộc',
      '0006': 'Đóng',
      '0007': 'Không có lịch sử trò chuyện',
      '0008': 'Mẫu đơn nhập cảnh',
      '0009': 'Ngôn ngữ nhận dạng',
      '0010': 'Cài đặt',
      '0011': 'Bắt đầu trò chuyện lại',
    },
  },
  th: {
    client: {
      '0001': 'กรุณากรอกคำถาม',
      '0002': 'กำหนด',
      '0003': 'คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการแชท',
      '0004': 'เตือน',
      '0005': 'กรุณากรอกแบบฟอร์มที่จำเป็น (*)',
      '0006': 'ใกล้',
      '0007': 'ไม่มีประวัติการแชท',
      '0008': 'รายการแบบฟอร์ม',
      '0009': 'ภาษาที่ใช้ในการจดจำ',
      '0010': 'การตั้งค่า',
      '0011': 'เริ่มแชทอีกครั้ง',
    },
  },
};
