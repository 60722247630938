import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import store from './store';
import router from './router';
import AppConfigs from '../appcode/AppConfigs';
import {
    BootstrapVue,
    BIconstack,
    BIconX,
    BIconCheckCircle,
    BIconDashCircle,
    BIconDashSquare,
    BIconPencil,
    BIconPlus,
    BIconEye,
    BIconPencilSquare,
    BIconPencilFill,
    BIconLink45deg,
    BIconInfoCircle,
    BIconCaretRightFill,
    BIconFileEarmarkExcel,
    BIconSearch,
    BIconArrowRepeat,
    BIconTrash,
    BIconPersonFill,
    BIconCheck2,
    BIcon,
    BIconPlusSquare,
    BIconCircleFill,
    BIconBezier,
    BIconListUl,
    BIconTools,
    BIconSortDown,
    BIconSortDownAlt,
    BIconArrowCounterclockwise,
    BIconLockFill,
    BIconList,
    BIconArrowRight,
    BIconClockHistory,
    BIconExclamationCircleFill
} from 'bootstrap-vue';
import dayjs from 'dayjs';
import VueI18n from 'vue-i18n';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import './main.css';
import {messages} from './resources/resources';

import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import axios from 'axios';

Vue.config.devtools = true;
Vue.use(VueI18n);
Vue.use(BootstrapVue);
Vue.component('BIconstack', BIconstack);
Vue.component('BIconX', BIconX);
Vue.component('BIconCheckCircle', BIconCheckCircle);
Vue.component('BIconDashCircle', BIconDashCircle);
Vue.component('BIconDashSquare', BIconDashSquare);
Vue.component('BIconPencil', BIconPencil);
Vue.component('BIconPlus', BIconPlus);
Vue.component('BIconEye', BIconEye);
Vue.component('BIconPencilSquare', BIconPencilSquare);
Vue.component('BIconPencilFill', BIconPencilFill);
Vue.component('BIconLink45deg', BIconLink45deg);
Vue.component('BIconInfoCircle', BIconInfoCircle);
Vue.component('BIconCaretRightFill', BIconCaretRightFill);
Vue.component('BIconFileEarmarkExcel', BIconFileEarmarkExcel);
Vue.component('BIconSearch', BIconSearch);
Vue.component('BIconArrowRepeat', BIconArrowRepeat);
Vue.component('BIconTrash', BIconTrash);
Vue.component('BIconPersonFill', BIconPersonFill);
Vue.component('BIconCheck2', BIconCheck2);
Vue.component('BIcon', BIcon);
Vue.component('BIconPlusSquare', BIconPlusSquare);
Vue.component('BIconCircleFill', BIconCircleFill);
Vue.component('BIconBezier', BIconBezier);
Vue.component('BIconListUl', BIconListUl);
Vue.component('BIconTools', BIconTools);
Vue.component('BIconSortDown', BIconSortDown);
Vue.component('BIconSortDownAlt', BIconSortDownAlt);
Vue.component('BIconArrowCounterclockwise', BIconArrowCounterclockwise);
Vue.component('BIconLockFill', BIconLockFill);
Vue.component('BIconList', BIconList);
Vue.component('BIconArrowRight', BIconArrowRight);
Vue.component('BIconClockHistory', BIconClockHistory);
Vue.component('BIconExclamationCircleFill', BIconExclamationCircleFill);

Vue.component('treeselect', Treeselect);

const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en',
    messages, // set locale messages
});

Vue.directive('focus', {
    inserted: function (el) {
        el.focus();
    },
});

Vue.config.productionTip = false;

Vue.prototype.$sanitize = function (propertyName) {
    let parts = propertyName.split('.');
    let partsLength = parts.length;
    // eslint-disable-next-line no-console
    console.log(propertyName);
    if (partsLength > 1) {
        let obj = this[parts[0]];

        for (let i = 1; i < partsLength - 1; i++) {
            let part = parts[i];
            if (obj !== null && typeof obj === 'object' && part in obj) {
                obj = obj[part];
            }
        }
        this.$set(
            obj,
            parts[partsLength - 1],
            obj[parts[partsLength - 1]].replace(/[<>\\]/g, '').replace(/\s\s+/g, ' ')
        );
    } else {
        this[propertyName] = this[propertyName]
            .replace(/[<>/\\]/g, '')
            .replace(/\s\s+/g, ' ');
    }
};

Vue.filter('date', function (value) {
    if (!value) return '';
    return dayjs(value).format('YYYY-MM-DD');
});

Vue.filter('datetime', function (value) {
    if (!value) return '';
    return dayjs(value).format('YYYY-MM-DD HH:mm');
});

Vue.filter('datetimelong', function (value) {
    if (!value) return '';
    if (!dayjs(value).isValid()) return value;
    return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
});

Vue.filter('datetimelongutz', function (value) {
    if (!value) return '';
    //incase the date is in UTC
    return dayjs(value + 'Z').format('YYYY-MM-DD HH:mm:ss');
});

Vue.mixin({
    methods: {
        _consoleLog(value) {
            // eslint-disable-next-line no-console
            console.log(value);
        },
        _execCopy() {
            document.execCommand('copy');
        },
        _numeric: function (value) {
            if (!value) return '';
            return value.trim().replace(/[^0-9]/gi, '');
        },
        _alphanumeric: function (value) {
            if (!value) return '';
            return value.trim().replace(/[^a-z0-9]/gi, '');
        },
        _alphanumericWithUnderscore: function (value) {
            if (!value) return '';
            return value.trim().replace(/[^a-z0-9_]/gi, '');
        },
        _sanitize: function (value) {
            if (!value) return '';
            return value
                .trim()
                .replace(/[<>\\]/g, '')
                .replace(/\s\s+/g, ' ');
        },
        _removeSpaces: function (value) {
            if (!value) return '';
            return value.trim().replace(/\s+/g, '');
        },
        _isImageFile(fileName) {
            if (fileName) {
                const allowedExts = [
                    'apng',
                    'gif',
                    'avif',
                    'jpg',
                    'jpeg',
                    'jfif',
                    'pjpeg',
                    'pjp',
                    'png',
                    'svg',
                    'webp',
                ];
                const ext = fileName.substring(fileName.lastIndexOf('.') + 1);
                return allowedExts.includes(ext.toLowerCase());
            }
            return false;
        },
        _downloadFile(url, formData, callback) {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onerror = function () {
                //handle error here
            };
            xhr.onload = function () {
                if (this.status !== 200) {
                    //handle error
                }

                if (this.status === 200) {
                    let filename = '';
                    let disposition = xhr.getResponseHeader('Content-Disposition');
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        let matches = filenameRegex.exec(disposition);
                        if (matches !== null && matches[1])
                            filename = matches[1].replace(/['"]/g, '');
                    }
                    let type = xhr.getResponseHeader('Content-Type');

                    let blob = new Blob([this.response], {type: type});
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created.
                        // These URLs will no longer resolve as the data backing the URL has been freed."
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        let URL = window.URL || window.webkitURL;
                        let downloadUrl = URL.createObjectURL(blob);

                        if (filename) {
                            // use HTML5 a[download] attribute to specify filename
                            var a = document.createElement('a');
                            // safari doesn't support this yet
                            if (typeof a.download === 'undefined') {
                                window.location = downloadUrl;
                            } else {
                                a.href = downloadUrl;
                                a.download = filename;
                                document.body.appendChild(a);
                                a.click();
                            }
                        } else {
                            window.location = downloadUrl;
                        }

                        setTimeout(function () {
                            URL.revokeObjectURL(downloadUrl);
                            document.body.removeChild(a);
                        }, 800); // cleanup
                    }
                }

                //handle on complete
                if (typeof callback === 'function') {
                    callback();
                }
            };
            //handle before send
            xhr.setRequestHeader('Content-type', 'application/json;charset=UTF-8');
            xhr.send(JSON.stringify(formData));
        },
        _downloadFileAxios(url, params, fileName) {
            return axios
                .get(url, {
                    ...{
                        responseType: 'arraybuffer',
                        headers: {'Cache-Control': 'no-cache, must-revalidate'},
                    },
                    ...params,
                })
                .then((resp) => {
                    if (resp.status === 200) {
                        let type = resp.headers['content-type'];
                        let blob = new Blob([resp.data], {type: type});

                        let URL = window.URL || window.webkitURL;
                        let downloadUrl = URL.createObjectURL(blob);

                        if (!fileName) {
                            let disposition = resp.headers['content-disposition'];
                            if (disposition && disposition.indexOf('attachment') !== -1) {
                                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                let matches = filenameRegex.exec(disposition);
                                if (matches != null && matches[1]) {
                                    fileName = matches[1].replace(/['"]/g, '');
                                }
                            }
                        }

                        // use HTML5 a[download] attribute to specify filename
                        let a = document.createElement('a');
                        // safari doesn't support this yet
                        if (typeof a.download === 'undefined') {
                            window.location = downloadUrl;
                        } else {
                            a.href = downloadUrl;
                            a.download = fileName;
                            document.body.appendChild(a);
                            a.click();
                        }
                        setTimeout(() => {
                            document.body.removeChild(a);
                        }, 800); // cleanup
                        setTimeout(() => {
                            URL.revokeObjectURL(downloadUrl);
                        }, 800); // cleanup
                    }
                });
        },
        _downloadFileWithBlob(url, fileName) {
            return axios
                .get(url, {
                    responseType: 'arraybuffer',
                    headers: {'Cache-Control': 'no-cache, must-revalidate'},
                })
                .then((resp) => {
                    if (resp.status === 200) {
                        let type = resp.headers['Content-Type'];
                        let blob = new Blob([resp.data], {type: type});
                        if (typeof window.navigator.msSaveBlob !== 'undefined') {
                            // eslint-disable-next-line no-console
                            console.log('blob');
                            window.navigator.msSaveBlob(blob, fileName);
                        } else {
                            let URL = window.URL || window.webkitURL;
                            let downloadUrl = URL.createObjectURL(blob);

                            if (fileName) {
                                // use HTML5 a[download] attribute to specify filename
                                let a = document.createElement('a');
                                // safari doesn't support this yet
                                if (typeof a.download === 'undefined') {
                                    window.location = downloadUrl;
                                } else {
                                    a.href = downloadUrl;
                                    a.download = fileName;
                                    document.body.appendChild(a);
                                    a.click();
                                }
                                setTimeout(() => {
                                    document.body.removeChild(a);
                                }, 800); // cleanup
                            } else {
                                window.location = downloadUrl;
                            }

                            setTimeout(() => {
                                URL.revokeObjectURL(downloadUrl);
                            }, 800); // cleanup
                        }
                    }
                });
        },
        _capitalizeFirstLetter(string) {
            return string[0].toUpperCase() + string.slice(1);
        },
        _modalDefaultOptions(options) {
            return {
                ...{
                    size: 'sm',
                    buttonSize: 'sm',
                    footerClass: 'p-2',
                    centered: true,
                },
                ...options,
            };
        },
        _tryParseJson(string) {
            try {
                return JSON.parse(string);
            } catch {
                return null;
            }
        },
        _warnError() {
            this.$bvModal.msgBoxOk('System error. Please try again later.', {
                centered: true, size: 'sm', buttonSize: 'sm', okVariant: 'warning'
            });
        },
    },
});

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');

AppConfigs.load();
setInterval(() => {
    AppConfigs.load();
}, 1000 * 60 * 5);
