import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import Home from '../views/Home/Home.vue';
import axios from 'axios';

Vue.use(VueRouter);

const botsAdminRole = 'BotsAdmin';
const chatHistoryViewerRole = 'ChatHistoriesViewer';
const usersAdminRole = 'UsersAdmin';
const skypeBotsRole = 'SkypeBotsAdmin';

const adminModule = 'admin';
const botModule = 'mario-bots';
const reportModule = 'report';
const userModule = 'settings';
const skypeBotModule = 'skype-bots';

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {allowAnonymous: true, module: ''},
        component: Home,
    },
    {
        path: '/bots',
        name: 'bots',
        meta: {title: 'Bots', requiredRole: botsAdminRole, module: botModule},
        component: () =>
            import(/* webpackChunkName: "v-bots" */ '@/views/Bots/Bots'),
    },
    {
        path: '/bot-settings/:id?',
        name: 'bot-settings',
        meta: {
            title: 'Bot Settings',
            requiredRole: botsAdminRole,
            module: botModule,
        },
        component: () =>
            import(
                /* webpackChunkName: "v-bot-settings" */ '@/views/Bots/BotSettings'
                ),
    },
    {
        path: '/client-settings/:id?',
        name: 'client-settings',
        meta: {
            title: 'Client Settings',
            requiredRole: botsAdminRole,
            module: botModule,
        },
        component: () =>
            import(
                /* webpackChunkName: "v-client-settings" */ '@/views/Bots/ClientSettings'
                ),
    },
    {
        path: '/system-messages',
        name: 'system-messages',
        meta: {
            title: 'System Messages',
            requiredRole: botsAdminRole,
            module: botModule,
        },
        component: () =>
            import(
                /* webpackChunkName: "v-system-messages" */ '@/views/Bots/SystemMessages'
                ),
    },
    {
        path: '/chat-rating',
        name: 'chat-rating',
        meta: {
            title: 'Chat Rating',
            requiredRole: botsAdminRole,
            module: botModule,
        },
        component: () =>
            import(/* webpackChunkName: "v-chat-rating" */ '@/views/Bots/ChatRating'),
    },
    {
        path: '/pre-chat',
        name: 'pre-chat',
        meta: {title: 'Pre Chat', requiredRole: botsAdminRole, module: botModule},
        component: () =>
            import(/* webpackChunkName: "v-pre-chat" */ '@/views/Bots/PreChat'),
    },
    {
        path: '/webhooks',
        name: 'webhooks',
        meta: {title: 'Webhooks', requiredRole: botsAdminRole, module: botModule},
        component: () =>
            import(/* webpackChunkName: "v-webhooks" */ '@/views/Bots/Webhooks'),
    },
    {
        path: '/new-intents',
        name: 'new-intents',
        meta: {
            title: 'Intents',
            requiredRole: botsAdminRole,
            module: botModule,
        },
        component: () =>
            import(/* webpackChunkName: "v-new-intents" */ '@/views/Intents/Intents'),
    },
    {
        path: '/chat-filters',
        name: 'chat-filters',
        meta: {
            title: 'Chat Filters',
            requiredRole: botsAdminRole,
            module: botModule,
        },
        component: () =>
            import(
                /* webpackChunkName: "v-chat-filters" */ '@/views/Bots/ChatFilters'
                ),
    },
    {
        path: '/train-bot',
        name: 'train-bot',
        meta: {
            title: 'Train Chat Bot',
            requiredRole: botsAdminRole,
            module: botModule,
        },
        component: () =>
            import(/* webpackChunkName: "v-train-bot" */ '@/views/Bots/TrainChatBot'),
    },
    {
        path: '/skype-bots',
        name: 'skype-bots',
        meta: {
            title: 'Skype Bots',
            requiredRole: skypeBotsRole,
            module: skypeBotModule,
        },
        component: () =>
            import(
                /* webpackChunkName: "v-skype-bots" */ '@/views/SkypeBots/SkypeBots.vue'
                ),
    },
    {
        path: '/anti-spam',
        name: 'skype-bot/anti-spam',
        meta: {
            title: 'Blacklist Management',
            requiredRole: skypeBotsRole,
            module: skypeBotModule,
        },
        component: () =>
            import(/* webpackChunkName: "v-anti-Spam" */ '@/views/SkypeBots/AntiSpam.vue' ),
    },
    {
        path: '/skype-system-messages',
        name: 'skype-bot/system-messages',
        meta: {
            title: 'System Messages',
            requiredRole: skypeBotsRole,
            module: skypeBotModule,
        },
        component: () =>
            import(/* webpackChunkName: "v-skype-bot-system-messages" */ '@/views/SkypeBots/SystemMessages.vue' ),
    },
    {
        path: '/skype-webhooks',
        name: 'skype-bot/webhooks',
        meta: {
            title: 'Webhooks',
            requiredRole: skypeBotsRole,
            module: skypeBotModule,
        },
        component: () =>
            import(/* webpackChunkName: "v-skype-bot-webhooks" */ '@/views/SkypeBots/Webhooks.vue' ),
    },
    {
        path: '/skype-intents',
        name: 'skype-bot/intents',
        meta: {
            title: 'Intents',
            requiredRole: skypeBotsRole,
            module: skypeBotModule,
        },
        component: () =>
            import(/* webpackChunkName: "v-skype-bot-intents" */ '@/views/SkypeBots/Intents.vue' ),
    },
    {
        path: '/skype-train-bots',
        name: 'skype-bot/train-bots',
        meta: {
            title: 'Train Bots',
            requiredRole: skypeBotsRole,
            module: skypeBotModule,
        },
        component: () =>
            import(/* webpackChunkName: "v-skype-bot-train-bots" */ '@/views/SkypeBots/TrainChatBot.vue' ),
    },
    {
        path: '/skype-chat-histories',
        name: 'skype-bot/chat-histories',
        meta: {
            title: 'Chat Histories',
            requiredRole: skypeBotsRole,
            module: skypeBotModule,
        },
        component: () =>
            import(/* webpackChunkName: "v-skype-bot-chat-histories" */ '@/views/SkypeBots/ChatHistories.vue' ),
    },
    {
        path: '/skype-api-usages',
        name: 'skype-bot/api-usages',
        meta: {
            title: 'API Usages',
            requiredRole: skypeBotsRole,
            module: skypeBotModule,
        },
        component: () =>
            import(/* webpackChunkName: "v-skype-bot-api-usages" */ '@/views/SkypeBots/ApiUsagesView.vue' ),
    },
    {
        path: '/anti-spam',
        name: 'anti-spam',
        meta: {
            title: 'Blacklist Management',
            requiredRole: skypeBotsRole,
            module: skypeBotModule,
        },
        component: () =>
            import(
                /* webpackChunkName: "v-anti-Spam" */ '@/views/SkypeBots/AntiSpam.vue'
                ),
    },
    {
        path: '/bot-audits',
        name: 'bot-audits',
        meta: {
            title: 'Audits',
            requiredRole: botsAdminRole,
            module: botModule,
        },
        component: () =>
            import(/* webpackChunkName: "v-bot-audits" */ '@/views/Bots/Audits'),
    },
    {
        path: '/chat-history',
        name: 'chat-history',
        meta: {
            title: 'Chat History',
            requiredRole: chatHistoryViewerRole,
            module: reportModule,
        },
        component: () =>
            import(
                /* webpackChunkName: "v-chat-history" */ '@/views/ChatHistories/ChatHistories'
                ),
    },
    {
        path: '/chat-history-summary',
        name: 'chat-history-summary',
        meta: {
            title: 'Chat History Summary',
            requiredRole: chatHistoryViewerRole,
            module: reportModule,
        },
        component: () =>
            import(
                /* webpackChunkName: "v-chat-history-summary" */ '@/views/ChatHistories/ChatHistorySummaries'
                ),
    },
    {
        path: '/chat-rating-report',
        name: 'chat-rating-report',
        meta: {
            title: 'Chat Rating Report',
            requiredRole: chatHistoryViewerRole,
            module: reportModule,
        },
        component: () =>
            import(
                /* webpackChunkName: "v-chat-rating-report" */ '@/views/ChatHistories/ChatRatingReport'
                ),
    },
    {
        path: '/chat-report',
        name: 'chat-report',
        meta: {
            title: 'Chat Report',
            requiredRole: chatHistoryViewerRole,
            module: reportModule,
        },
        component: () =>
            import(
                /* webpackChunkName: "v-chat-report" */ '@/views/ChatHistories/ChatCountReport'
                ),
    },
    {
        path: '/active-chats',
        name: 'active-chats',
        meta: {
            title: 'Active Chats',
            requiredRole: chatHistoryViewerRole,
            module: reportModule,
        },
        component: () =>
            import(
                /* webpackChunkName: "v-active-chats" */ '@/views/ChatHistories/ActiveChats'
                ),
    },
    {
        path: '/trusted-ip',
        name: 'trusted-ip',
        meta: {
            title: 'Trusted IPs',
            requiredRole: usersAdminRole,
            module: userModule,
        },
        component: () =>
            import(
                /* webpackChunkName: "v-trusted-ip" */ '@/views/Settings/TrustedIPs'
                ),
    },
    {
        path: '/users-management',
        name: 'users-management',
        meta: {
            title: 'Users Management',
            requiredRole: usersAdminRole,
            module: userModule,
        },
        component: () =>
            import(
                /* webpackChunkName: "v-users-management" */ '@/views/Settings/Users'
                ),
    },
    {
        path: '/audits',
        name: 'audits',
        meta: {
            title: 'Audits',
            isAdminOnly: true,
            module: adminModule,
        },
        component: () =>
            import(/* webpackChunkName: "v-audits" */ '@/views/Admin/Audits'),
    },
    {
        path: '/application-configs',
        name: 'application-configs',
        meta: {
            title: 'Application Configs',
            isAdminOnly: true,
            module: adminModule,
        },
        component: () =>
            import(
                /* webpackChunkName: "v-application-configs" */ '@/views/Admin/ApplicationConfigs'
                ),
    },
    {
        path: '/admin-bot-management',
        name: 'admin-bot-management',
        meta: {
            title: 'Admin Bot Management',
            isAdminOnly: true,
            module: adminModule,
        },
        component: () =>
            import(
                /* webpackChunkName: "v-admin-bot-management" */ '@/views/Admin/AdminBotManagement'
                ),
    },
    {
        path: '/intentsummary',
        name: 'intent-summary',
        meta: {
            title: 'Intent Summary',
            requiredRole: chatHistoryViewerRole,
            module: reportModule,
        },
        component: () =>
            import(
                /* webpackChunkName: "v-intent-summary" */ '@/views/ChatHistories/IntentSummary'
                ),
    },
    {
        path: '/intent-mapping',
        name: 'intent-mapping',
        meta: {
            title: 'Intent Mapping',
            requiredRole: botsAdminRole,
            module: botModule,
        },
        component: () =>
            import(/* webpackChunkName: "v-main" */ '@/views/Intents/IntentMapping'),
    },
    {
        path: '/apiUsages',
        name: 'api-usages',
        meta: {
            title: 'API Usages',
            requiredRole: chatHistoryViewerRole,
            module: reportModule,
        },
        component: () =>
            import(
                /* webpackChunkName: "v-api-usages" */ '@/views/reports/ApiUsagesView'
                ),
    },
    {
        path: '*',
        name: 'notfound',
        meta: {
            title: 'Not Found',
            allowAnonymous: true,
            module: '',
        },
        component: () =>
            import(/* webpackChunkName: "v-notfound" */ '@/views/NotFound'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

// eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, from, next) => {
    function _isAuthorized(userInfo) {
        if (to.matched.some((record) => record.meta.allowAnonymous)) {
            store.commit('updatePageTitle', to.meta.title);
            store.commit('updateCurrentModule', to.meta.module);
            return true;
        } else {
            if (userInfo.name) {
                //check the roles
                if (
                    to.matched.some((record) => record.meta.isAdminOnly) ||
                    to.matched.some((record) => record.meta.requiredRole)
                ) {
                    if (
                        userInfo.isAdmin ||
                        (!to.meta.isAdminOnly &&
                            userInfo.roles.includes(to.meta.requiredRole))
                    ) {
                        store.commit('updatePageTitle', to.meta.title);
                        store.commit('updateCurrentModule', to.meta.module);
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        }
    }

    next();
    // check from server side anyway,
    // update the userinfo from server side as well
    // but no need to await
    const instance = axios.create({
        baseURL: window.location.origin
    });
    instance
        .get('/api/security')
        .then((resp) => {
            if (resp.data) {
                const userInfo = resp.data;
                store.commit('updateUserInfo', userInfo);
                if (!_isAuthorized(userInfo)) {
                    window.location.href = '/identity/account/accessDenied?ReturnUrl=%2F';
                }
            } else {
                window.location.href = '/identity/account/login?ReturnUrl=%2F';
            }
        })
        .catch((error) => {
            store.commit('updateUserInfo', null);
            if (error.response.status === 401) {
                window.location.href = '/identity/account/login?ReturnUrl=%2F';
            } else if (error.response.status === 403) {
                window.location.href = '/identity/account/accessDenied?ReturnUrl=%2F';
            }
        });
});

export default router;
